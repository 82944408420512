import { CSSProperties, useCallback, useEffect, useState } from "react";
// components
import { Spinner } from "features/driverInstall/components/Spinner";
import { FormattedMessage } from "react-intl";
// assets
import bg from "assets/img/downloadLander/downloadBlueBackground.png";
import chromeStep01 from "assets/img/downloadLander/chrome_step_01.png";
import chromeStep03 from "assets/img/downloadLander/chrome_step_03.png";
import firefoxStep03 from "assets/img/downloadLander/firefox_step_03.png";
import edgeStep01 from "assets/img/downloadLander/edge_step_01.png";
import edgeStep02 from "assets/img/downloadLander/edge_step_02.png";
import edgeStep03 from "assets/img/downloadLander/edge_step_03.png";
import step02 from "assets/img/downloadLander/step_02.png";
import verticalIndicator from "assets/img/downloadLander/vertical_download_indicator.png";
import horizontalIndicator from "assets/img/downloadLander/horizontal_download_indicator.png";
import { FixedFormattedMessage } from "features/SubscriptionManagement/MyAccount/components/FixedFormattedMessage";
import styled from "@mui/styled-engine";
import { SupportedBrowser } from "utils/browserUtils";
// types
// --------------------------------------

type StepProps = {
  number: number;
  image: string;
  title: React.ReactNode;
  description: React.ReactNode;
};

export type IDownloadLanderProps = {
  downloadFunction: () => void;
  currentBrowser: SupportedBrowser;
  isChrome115OrHigher: boolean;
  isLoading: boolean;
};

// styled components
// --------------------------------------
const Container = styled("div")`
  height: 100%;
  width: 100%;
`;

const Header = styled("header")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled("h1")`
  color: #535353;
  font: normal normal normal 2.625rem/2.1875rem Roboto;
  letter-spacing: 0.31px;
  text-align: center;
  margin: 0;
  margin-top: 32px;
`;

const Instruction = styled("p")`
  font: normal normal normal 1.3125rem/1.75rem Roboto;
  letter-spacing: 0.15px;
  text-align: center;

  margin: 0;
  margin-top: 16px;
`;

const InstructionClick = styled(Instruction)`
  margin: 0;
`;

const InlineLinkButton = styled("button")`
  all: unset;
  color: #007dba;
  cursor: pointer;
  text-decoration: underline;
`;

const StepsSection = styled("section")`
  background: transparent url(${bg}) no-repeat center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  height: 470px;
  width: 100%;
`;

const StepsContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 902px;
`;

const StepContainer = styled("div")`
  background: #ffffff;
  box-shadow: 0px 10px 20px #4e4e4e29;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  height: 334px;
  width: 280px;
`;

const StepHeader = styled("header")`
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-left: 40px;
  width: 100%;
`;

const StepNumber = styled("span")`
  border-radius: 50%;
  background-color: #079a1a;
  color: #ffffff;
  font: normal normal 700 1rem/2.1875rem Roboto;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
`;

const StepTitle = styled("h2")`
  color: #535353;
  font: normal normal 500 1rem/2.1875rem Roboto;
  letter-spacing: 0.12px;
  margin: 0;
  margin-left: 12px;
  text-align: left;
`;

const StepImage = styled("img")`
  margin-top: 12px;
`;

const StepDescription = styled("p")`
  bottom: 8px;
  color: #535353;
  font: normal normal normal 1rem/1.3125rem Roboto;
  height: 70px;
  letter-spacing: 0.12px;
  position: absolute;
  text-align: center;
  margin: 0;
  width: 232px;
`;

const Footer = styled("footer")`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 48px;
`;

const FooterContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 900px;
`;

const Disclaimer = styled("div")`
  margin-top: 32px;
`;

const DisclaimerParagraph = styled("p")`
  font: normal normal normal 0.75rem/1.125rem Roboto;
  letter-spacing: 0px;
  text-align: center;
  margin: 0;
`;

const DisclaimerLink = styled("a")`
  color: #007dba;
  font: normal normal normal 0.75rem/1.125rem Roboto;
  letter-spacing: 0px;
  text-decoration: underline;
`;

const Divider = styled("div")`
  border: 1px solid #cbcbcb;
  margin-top: 24px;
  width: 100%;
`;

const FooterList = styled("ul")`
  display: flex;
  list-style: none;
  line-height: 0;
  padding: 0;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 24px;
  & > *:not(:last-child) {
    padding-right: 4px;
    border-right: 1px solid #535353;
  }
`;

const FooterListItem = styled("li")`
  list-style: none;
`;

const FooterLink = styled("a")`
  color: #007dba;
  font: normal normal normal 1rem/1.125rem Roboto;
  letter-spacing: 0px;
  text-decoration: underline;
`;

const Copyright = styled("div")`
  width: 848px;
`;

const CopyrightParagraph = styled("p")`
  color: #535353;
  font: normal normal normal 0.75rem/1.125rem Roboto;
  letter-spacing: 0px;
  text-align: left;
`;

const Overlay = styled("div")`
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;

const Indicator = styled("img")`
  position: fixed;
  z-index: 1001;
`;

const HIndicator = styled(Indicator)`
  animation-name: horizontalBounce;
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  @keyframes horizontalBounce {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(275px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

const VIndicator = styled(Indicator)`
  animation-name: verticalBounce;
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  @keyframes verticalBounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

function getFirstStepImage(browser?: SupportedBrowser) {
  switch (browser) {
    case "firefox": //intentional fall-through since Firefox updated their UI
    case "edge":
      return edgeStep01;
    case "chrome":
    default:
      return chromeStep01;
  }
}

function getSecondStepImage(browser?: SupportedBrowser) {
  switch (browser) {
    case "edge":
      return edgeStep02;
    case "chrome":
    case "firefox":
    default:
      return step02;
  }
}

function getThirdStepImage(browser?: SupportedBrowser) {
  switch (browser) {
    case "firefox":
      return firefoxStep03;
    case "edge":
      return edgeStep03;
    case "chrome":
    default:
      return chromeStep03;
  }
}

function getFirstStepDescription(browser?: SupportedBrowser) {
  switch (browser) {
    case "firefox":
      return (
        <FormattedMessage
          data-qatag="DownloadLander.StepOneDescriptionMsg"
          id="DownloadLander.StepOneDescription"
          defaultMessage='Click <b>"DSOneWeb.exe"</b> at the top right of your screen.'
          values={{
            b: (chunks) => <b data-qatag="bold">{chunks}</b>,
          }}
        />
      );
    case "edge":
      return (
        <FormattedMessage
          data-qatag="DownloadLander.StepOneDescriptionMsg"
          id="DownloadLander.StepOneDescription"
          defaultMessage='Click <b>"DriverSupport.exe"</b> at the top right of your screen.'
          values={{
            b: (chunks) => <b data-qatag="bold">{chunks}</b>,
          }}
        />
      );
    case "chrome":
    default:
      return (
        <FormattedMessage
          data-qatag="DownloadLander.StepOneDescriptionMsg"
          id="DownloadLander.StepOneDescription"
          defaultMessage='Click <b>"DriverSupport.exe"</b> at the left bottom of your screen.'
          values={{
            b: (chunks) => <b data-qatag="bold">{chunks}</b>,
          }}
        />
      );
  }
}

function getVIndicatorInitialPosition(isChromeVersion115OrHigher: boolean, browser?: SupportedBrowser): CSSProperties {
  switch (browser) {
    case "chrome":
      if (isChromeVersion115OrHigher) {
        return {};
      }
      return {
        bottom: 1,
        left: "50px",
      };
    default:
      return {};
  }
}

function getHIndicatorInitialPosition(isChromeVersion115OrHigher: boolean, browser?: SupportedBrowser): CSSProperties {
  switch (browser) {
    case "edge":
      return {
        top: "24px",
        right: 768,
      };
    case "chrome":
      // chrome 115+ has a new download bar
      if (isChromeVersion115OrHigher) {
        return {
          top: "24px",
          right: 768,
        };
      }
      return {};
    case "firefox":
      return {
        top: "24px",
        right: 768,
      };
    default:
      return {};
  }
}

// constants
// --------------------------------------
const FOOTER_LINKS = [
  {
    text: "Software Principles",
    url: "http://www.solveiq.com/home/software-principles",
  },
  {
    text: "Privacy Policy",
    url: "http://www.solveiq.com/home/privacy-policy",
  },
  {
    text: "Terms and Conditions",
    url: "http://www.solveiq.com/home/terms-and-conditions",
  },
  {
    text: "Cookie Policy",
    url: "https://www.driversupport.com/cookiepolicy/",
  },
  {
    text: "Refund Policy",
    url: "http://www.solveiq.com/home/terms-and-conditions",
  },
  {
    text: "Uninstall Instructions",
    url: "http://www.solveiq.com/home/uninstall",
  },
];

// "compound" components
// --------------------------------------
function Step({ number, image, title, description }: StepProps) {
  return (
    <StepContainer data-qatag="StepContainer">
      <StepHeader data-qatag="StepHeader">
        <StepNumber data-qatag="StepNumber">{number}</StepNumber>
        <StepTitle data-qatag="StepTitle">{title}</StepTitle>
      </StepHeader>
      <StepImage
        data-qatag="StepImage"
        src={image}
        alt={`Step ${number}`}
      />
      <StepDescription data-qatag="StepDescription">{description}</StepDescription>
    </StepContainer>
  );
}

// component
// --------------------------------------
export function NewDownloadLander({
  downloadFunction,
  currentBrowser,
  isChrome115OrHigher,
  isLoading,
}: IDownloadLanderProps) {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showVerticalIndicator, setShowVerticalIndicator] = useState(false);
  const [showHorizontalIndicator, setShowHorizontalIndicator] = useState(false);

  const playAnimation = useCallback(() => {
    setShowOverlay(true);

    switch (currentBrowser) {
      case "firefox":
        setShowHorizontalIndicator(true);
        return;
      case "edge":
        setShowHorizontalIndicator(true);
        return;
      case "chrome":
        if (isChrome115OrHigher) {
          setShowHorizontalIndicator(true);
        } else {
          setShowVerticalIndicator(true);
        }
        return;
      default:
        setShowVerticalIndicator(true);
        return;
    }
  }, [currentBrowser]);

  // Trigger download 1.5 seconds after the URL is ready.
  useEffect(() => {
    const id = window.setTimeout(() => {
      downloadFunction();
      playAnimation();
    }, 1500);

    return () => {
      clearTimeout(id);
    };
  }, []);

  const stopAnimation = useCallback(() => {
    setShowOverlay(false);
    setShowVerticalIndicator(false);
    setShowHorizontalIndicator(false);
  }, []);

  return (
    <Container data-qatag="DownloadLander">
      <Header data-qatag="DownloadLander.Header">
        <Title data-qatag="DownloadLander.Title">
          <FormattedMessage
            data-qatag="DownloadLander.TitleMsg"
            id="DownloadLander.Title"
            defaultMessage="Almost done!"
          />
        </Title>
        <Instruction data-qatag="DownloadLander.Instructions">
          <FormattedMessage
            data-qatag="DownloadLander.InstructionsMsg"
            id="DownloadLander.Instructions"
            defaultMessage="Follow these 3 easy steps to complete your installation"
          />
        </Instruction>
        {isLoading ? (
          <Spinner data-qatag="DownloadLander.Loader" />
        ) : (
          <InstructionClick data-qatag="DownloadLander.InstructionsClickHere">
            <FormattedMessage
              data-qatag="DownloadLander.InstructionsClickHereMsg"
              id="DownloadLander.InstructionsClickHere"
              defaultMessage="If your download hasn't already started, <link>click here</link>"
              values={{
                link: (chunks) => (
                  <InlineLinkButton
                    data-qatag="DownloadLander.ClickHere"
                    onClick={downloadFunction}
                  >
                    {chunks}
                  </InlineLinkButton>
                ),
              }}
            />
          </InstructionClick>
        )}
      </Header>

      <StepsSection data-qatag="DownloadLander.StepsSection">
        <StepsContainer data-qatag="DownloadLander.StepsContainer">
          <Step
            data-qatag="DownloadLander.StepOne"
            number={1}
            title={
              <FormattedMessage
                data-qatag="DownloadLander.StepOneTitleMsg"
                id="DownloadLander.StepOneTitle"
                defaultMessage="Run the installer"
              />
            }
            image={getFirstStepImage(currentBrowser)}
            description={getFirstStepDescription(currentBrowser)}
          />
          <Step
            data-qatag="DownloadLander.StepTwo"
            number={2}
            title={
              <FormattedMessage
                data-qatag="DownloadLander.StepTwoTitleMsg"
                id="DownloadLander.StepTwoTitle"
                defaultMessage="Confirm installation"
              />
            }
            image={getSecondStepImage(currentBrowser)}
            description={
              <FormattedMessage
                data-qatag="DownloadLander.StepTwoDescriptionMsg"
                id="DownloadLander.StepTwoDescription"
                defaultMessage='Click <b>"Yes"</b> on the system dialog window to confirm your installation'
                values={{
                  b: (chunks) => <b data-qatag="bold">{chunks}</b>,
                }}
              />
            }
          />
          <Step
            data-qatag="DownloadLander.StepThree"
            number={3}
            title={
              <FormattedMessage
                data-qatag="DownloadLander.StepThreeTitleMsg"
                id="DownloadLander.StepThreeTitle"
                defaultMessage="Complete setup"
              />
            }
            image={getThirdStepImage(currentBrowser)}
            description={
              <FormattedMessage
                data-qatag="DownloadLander.StepThreeDescriptionMsg"
                id="DownloadLander.StepThreeDescription"
                defaultMessage="Follow the easy installation wizard and start optimizing!"
              />
            }
          />
        </StepsContainer>
      </StepsSection>
      <Footer data-qatag="DownloadLander.Footer">
        <FooterContainer data-qatag="DownloadLander.Footer">
          <Disclaimer data-qatag="DownloadLander.Disclaimer">
            <DisclaimerParagraph data-qatag="DownloadLander.DisclaimerPrinciples">
              <FormattedMessage
                data-qatag="DownloadLander.DisclaimerPrinciplesMsg"
                id="DownloadLander.DisclaimerPrinciples"
                defaultMessage="Driver Support is an independent service provider for software
              products. It is a tool to help identify out-of-date or missing
              device drivers. <link>Software Principles</link>"
                values={{
                  link: (chunks) => (
                    <DisclaimerLink
                      data-qatag="DownloadLander.SoftwarePrinciplesLink"
                      href="http://www.solveiq.com/home/software-principles"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {chunks}
                    </DisclaimerLink>
                  ),
                }}
              />
            </DisclaimerParagraph>
            <DisclaimerParagraph data-qatag="DownloadLander.DisclaimerPolicy">
              <FormattedMessage
                data-qatag="DownloadLander.DisclaimerPolicyMsg"
                id="DownloadLander.DisclaimerPolicy"
                defaultMessage="By downloading you accept the <privacyLink>Privacy Policy</privacyLink>
              and <termsLink>Terms and Conditions</termsLink>. Full functionality requires $9.99 monthly
              subscription."
                values={{
                  privacyLink: (chunks) => (
                    <DisclaimerLink
                      data-qatag="DownloadLander.PrivacyPolicyLink"
                      href="http://www.solveiq.com/home/privacy-policy"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {chunks}
                    </DisclaimerLink>
                  ),
                  termsLink: (chunks) => (
                    <DisclaimerLink
                      data-qatag="DownloadLander.TermsPolicyLink"
                      href="http://www.solveiq.com/home/terms-and-conditions"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {chunks}
                    </DisclaimerLink>
                  ),
                }}
              />
            </DisclaimerParagraph>
            <DisclaimerParagraph data-qatag="DownloadLander.DisclaimerTrademark">
              <FormattedMessage
                data-qatag="DownloadLander.DisclaimerTrademarkMsg"
                id="DownloadLander.DisclaimerTrademark"
                defaultMessage="Use of names and trademarks are for reference only and no
              affiliation is implied with any named third-party companies."
              />
            </DisclaimerParagraph>
          </Disclaimer>

          <Divider data-qatag="DownloadLander.Divider" />

          <FooterList data-qatag="DownloadLander.FooterList">
            {FOOTER_LINKS.map(({ text, url }) => (
              <FooterListItem
                data-qatag="DownloadLander.FooterListItem"
                key={text}
              >
                <FooterLink
                  data-qatag="DownloadLander.FooterListItemLink"
                  href={url}
                  rel="noreferrer"
                  target="_blank"
                >
                  <FixedFormattedMessage
                    data-qatag="DownloadLander.FooterListItemLinkMsg"
                    id={`DownloadLander.${text.replace(/\s/g, "")}Link`}
                    defaultMessage={text}
                  />
                </FooterLink>
              </FooterListItem>
            ))}
          </FooterList>

          <Copyright data-qatag="DownloadLander.Copyright">
            <CopyrightParagraph data-qatag="DownloadLander.CopyrightFirstParagraph">
              <FormattedMessage
                data-qatag="DownloadLander.CopyrightFirstParagraphMsg"
                id="DownloadLander.CopyrightFirstParagraph"
                defaultMessage="© 1996-{currentYear} Driver Support. All rights reserved. Driver Support, the
              Driver Support logo, and driversupport.com are trademarks of Driver
              Support and its affiliated companies."
                values={{
                  currentYear: new Date().getFullYear(),
                }}
              />
            </CopyrightParagraph>

            <CopyrightParagraph data-qatag="DownloadLander.CopyrightSecondParagraph">
              <FormattedMessage
                data-qatag="DownloadLander.CopyrightSecondParagraphMsg"
                id="DownloadLander.CopyrightSecondParagraph"
                defaultMessage="All third party products, brands or trademarks listed above are the
              sole property of their respective owner. No affiliation or endorsement
              is intended or implied."
              />
            </CopyrightParagraph>

            <CopyrightParagraph data-qatag="DownloadLander.CopyrightThirdParagraph">
              <FormattedMessage
                data-qatag="DownloadLander.CopyrightThirdParagraphMsg"
                id="DownloadLander.CopyrightThirdParagraph"
                defaultMessage="You may uninstall Driver Support at any time, using the standard
              uninstall procedures as offered with your computer's Operating
              System, by accessing the computer's “Control Panel&gt;Add/Remove
              Programs” folder, selecting 'Driver Support' from the list
              of installed applications, and clicking the “Uninstall” button."
              />
            </CopyrightParagraph>
          </Copyright>
        </FooterContainer>
      </Footer>

      {showOverlay && (
        <Overlay
          data-qatag="DownloadLander.Overlay"
          onClick={stopAnimation}
        />
      )}
      {showVerticalIndicator && (
        <VIndicator
          data-qatag="DownloadLander.vIndicator"
          style={getVIndicatorInitialPosition(isChrome115OrHigher, currentBrowser)}
          src={verticalIndicator}
          alt="Download indicator"
        />
      )}
      {showHorizontalIndicator && (
        <HIndicator
          data-qatag="DownloadLander.hIndicator"
          style={getHIndicatorInitialPosition(isChrome115OrHigher, currentBrowser)}
          src={horizontalIndicator}
          alt="Download indicator"
        />
      )}
    </Container>
  );
}

export default NewDownloadLander;
