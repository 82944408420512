import { useGetBillingSummaryQuery } from "api/BillingApi/BillingApi";
import { licenseApi } from "api/LicenseApi/LicenseApi";
import {
  useGetSubscriptionQuery,
  useGetUpdatePreviewQuery,
  useUpgradeSubscriptionMutation,
} from "api/SubscriptionApi/SubscriptionApi";
import PremiumUpgrade from "features/SubscriptionManagement/PremiumUpgrade/PremiumUpgradeContainer";
import useLicenseCheck from "features/settings/useLicenseCheck";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectIsAdmin } from "session/SessionSlice";
import UpSellPromotion from "./UpSellPromotion";

function UpSellPromotionContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector(selectIsAdmin);
  const [waitingForChanges, setWaitingForChanges] = useState<boolean>(false);

  const { data: subscription, isFetching: isSubscriptionFetching } = useGetSubscriptionQuery(undefined, {
    skip: !isAdmin,
  });

  // Retrieve prices for current subscription
  const {
    data: billingSummary,
    isFetching: isBillingSummaryFetching,
    error: billingSummaryError,
  } = useGetBillingSummaryQuery({});

  // Retrieve prices for upgraded subscription
  const {
    data: updatePreview,
    isFetching: isUpdatePreviewFetching,
    isUninitialized: isUpdatePreviewUninitialized,
    error: updatePreviewError,
  } = useGetUpdatePreviewQuery(
    {
      checkoutModelID: subscription?.upgradeCheckoutModel ?? "",
    },
    {
      skip: isSubscriptionFetching && typeof subscription?.upgradeCheckoutModel !== "string",
    }
  );

  // upgrade to premium
  const [
    upgradeSubscription,
    {
      isError: errorUpgradingSubscription,
      isLoading: isUpgradingSubscription,
      isSuccess: isUpgradingSuccess,
      reset: resetUpgradingSubscription,
    },
  ] = useUpgradeSubscriptionMutation({ fixedCacheKey: "upsell" });

  function reportUpsellConversion() {
    //GTM upsell event
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const untypedWindow = window as any;
    untypedWindow.dataLayer.push({ event: "UpsellEvent" });
  }

  const handleUpgrade = async () => {
    if (!subscription?.upgradeCheckoutModel) return;

    setWaitingForChanges(true);

    //updgrade subscription
    const upgradeData = await upgradeSubscription({
      checkoutModelID: subscription?.upgradeCheckoutModel,
    }).unwrap();
    if (upgradeData) dispatch(licenseApi.util.invalidateTags(["License"]));

    reportUpsellConversion();

    // Wait for the upsell upgrades to load in the backend
    // This timeout is a hack since the upgrade process is async we have no way
    // of knowing when it finishes.
    setTimeout(() => {
      onLicenseCheckClicked();
      setWaitingForChanges(false);
    }, 8000);
  };

  const { licenseCheckStatus, onLicenseCheckClicked } = useLicenseCheck();

  // Verify if the upgrade subscription status is loading
  const isLoading =
    isBillingSummaryFetching ||
    isUpdatePreviewFetching ||
    isSubscriptionFetching ||
    isUpgradingSubscription ||
    waitingForChanges ||
    licenseCheckStatus === "pending";

  const isNoUpgradeAvailable =
    updatePreviewError !== undefined ||
    billingSummaryError !== undefined ||
    (!isUpdatePreviewUninitialized && !isUpdatePreviewFetching && (updatePreview?.AmountWithoutTax ?? 0) <= 0);

  const currentSeatCount = 1;
  const additionalDevicesCount = updatePreview?.UpdateSeatCount ? updatePreview?.UpdateSeatCount - currentSeatCount : 0;

  // Verify if the upgrade subscription status is error
  const isError = errorUpgradingSubscription;

  const handleSkip = () => {
    navigate(-1);
  };

  return (
    <>
      {isUpgradingSuccess && updatePreview !== undefined ? (
        <PremiumUpgrade
          data-qatag="PremiumUpgrade"
          recurringCharge={updatePreview?.NextBillingChargeAmount}
          total={updatePreview?.AmountWithoutTax}
        />
      ) : (
        <UpSellPromotion
          handleUpgradeSubscription={handleUpgrade}
          additionalDevicesCount={additionalDevicesCount}
          handleSkip={handleSkip}
          handleResetUpgradeStatus={resetUpgradingSubscription}
          isLoading={isLoading}
          isSuccess={isUpgradingSuccess}
          isError={isError}
          isPremium={isNoUpgradeAvailable}
          price={billingSummary?.NextBillingChargeAmount}
          todayCharge={updatePreview?.AmountWithoutTax}
          monthlyPrice={updatePreview?.NextBillingChargeAmount}
          rateMonthly={updatePreview?.NextBillingChargeAmount}
          data-qatag="UpSellPromotion"
        />
      )}
    </>
  );
}

export default UpSellPromotionContainer;
