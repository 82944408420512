import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectCurrentUser } from "session/SessionSlice";
import DownloadLander from "./DownloadLander";
import { DownloadError } from "features/downloadPage/DownloadError";
import { getCurrentBrowser, isChromeVersion115OrHigher } from "utils/browserUtils";
import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import {
  allocateLicenseAndDownload,
  downloadTokenizedInstaller,
  selectAllocateAndDownloadStatus,
  selectDownloadTokenizedInstallerStatus,
} from "model/organization/OrganizationSlice";
import { ReducerStatus } from "model/IReducerState";

export function DownloadLanderContainer() {
  const currentUser = useSelector(selectCurrentUser);
  const allocatedAndDownloadStatus = useSelector(selectAllocateAndDownloadStatus);
  const downloadTokenizedInstallerStatus = useSelector(selectDownloadTokenizedInstallerStatus);
  const dispatch = useDispatch();

  //grab org licenses
  const {
    data: orgLicenses,
    isLoading: isOrgLicensesLoading,
    error: isOrgLicenseError,
  } = useGetOrganizationLicensesQuery();

  const navigate = useNavigate();

  const errorCallback = () => {
    navigate("/");
  };

  //if we need to allocate a license and have seats, lets do that then download, otherwise just download
  const downloadFunction = () => {
    if (orgLicenses && orgLicenses.availableSeats > 0 && orgLicenses.allocatedLicenses.length === 0) {
      dispatch(allocateLicenseAndDownload());
    } else {
      dispatch(downloadTokenizedInstaller());
    }
  };

  const isLoading = isOrgLicensesLoading;

  const hasError =
    !currentUser ||
    isOrgLicenseError ||
    allocatedAndDownloadStatus === ReducerStatus.Failed ||
    downloadTokenizedInstallerStatus === ReducerStatus.Failed;

  if (hasError) {
    return (
      <DownloadError
        data-qatag="DownloadError"
        errorCallback={errorCallback}
      />
    );
  }

  const currentBrowser = getCurrentBrowser();

  const isChrome115OrHigher = isChromeVersion115OrHigher();

  return (
    <DownloadLander
      data-qatag="DownloadLander"
      downloadFunction={downloadFunction}
      currentBrowser={currentBrowser}
      isChrome115OrHigher={isChrome115OrHigher}
      isLoading={isLoading}
    />
  );
}
