import { useSelector } from "react-redux";
import { NewPCDetected } from "./NewPCDetected";
import { selectCurrentUser } from "session/SessionSlice";
import { useGetOrganizationLicensesQuery } from "api/LicenseApi/LicenseApi";
import { GetUserHasAllocatedLicenses } from "api/LicenseApi/LicenseApiHelpers";
import { Navigate, useNavigate } from "react-router";
import { Loading } from "main/Loading";

export function NewPCDetectedContainer() {
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = currentUser?.attributes?.toLowerCase()?.includes("admin") ?? false;

  const {
    data: licensesData,
    isLoading: isLicensesDataLoading,
    error: licensesDataError,
  } = useGetOrganizationLicensesQuery();

  const hasAllocatedLicense = GetUserHasAllocatedLicenses(licensesData, currentUser);

  const downloadCallback = async () => {
    navigate("/downloadlander");
  };

  const declineCallback = () => {
    navigate("/family");
  };

  const isLoading = isLicensesDataLoading;
  const hasError = licensesDataError;

  if (isLoading) {
    return <Loading data-qatag="newPCDetectedLoading" />;
  }

  if (hasError) {
    return (
      <Navigate
        data-qatag="cannotLicensePCNavigate"
        to="/cannotlicensepc"
      />
    );
  }

  //family member with no allocated license send to cannotlicensepc page
  if (!isAdmin && !hasAllocatedLicense) {
    return (
      <Navigate
        data-qatag="cannotLicensePCNavigate"
        to="/cannotlicensepc"
      />
    );
  }
  return (
    <NewPCDetected
      data-qatag="NewPCDetected"
      downloadHandler={downloadCallback}
      cancelHandler={declineCallback}
      allowCancel={isAdmin}
    />
  );
}
