import React, { useEffect } from "react";
import { useAuth } from "siq-oidc-react";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccessful, selectLoginSuccessfulStatus } from "session/SessionSlice";
import { ReducerStatus } from "model/IReducerState";
import { Spinner } from "dsoneweb.designsystem";
import { useIntl } from "react-intl";

declare const REACT_ENV: string;
declare const signupUrl: string;

type Props = {
  children?: React.ReactNode;
};

const SessionWrapper = ({ children }: Props) => {
  const auth = useAuth();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const loginStatus = useSelector(selectLoginSuccessfulStatus);

  useEffect(() => {
    if (auth.userData) {
      dispatch(loginSuccessful(auth.userData));
    }
  }, [auth.userData, dispatch]);

  // keep from flashing the UI while the auth library is loading
  // and looking for cached creds
  if (auth.isLoading || loginStatus === ReducerStatus.Loading || loginStatus === ReducerStatus.Idle) {
    return (
      <Spinner
        data-qatag="loadingSpinner"
        isActive
        headerText={formatMessage({
          id: `SessionLoaderHeaderText`,
          defaultMessage: "Driver Support",
        })}
        text={formatMessage({
          id: `SessionLoaderText`,
          defaultMessage: "Software Is Loading...",
        })}
      />
    );
  }

  //they logged in but don't have a license, so go to signup
  if (loginStatus === ReducerStatus.Failed) {
    if (REACT_ENV !== "development") {
      window.location.assign(signupUrl);
    } else {
      throw new Error(`Login failed`);
    }
  }

  return <>{children}</>;
};

export default SessionWrapper;
