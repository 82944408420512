export interface ISystemInfoInfo {
  pcModel: string;
  operatingSystem: string;
  totalMemory: string;
  ispLogoUrl: string | null;
  pcImageUrl: string;
  downloadSpeed: string;
  uploadSpeed: string;
  ping: string;
  isp: string;
  networkScanTime: string;
}

export function getNewSystemInfoInfo(): ISystemInfoInfo {
  return {
    pcModel: "",
    operatingSystem: "Unknown",
    totalMemory: "Unknown",
    ispLogoUrl: null,
    pcImageUrl: "",
    downloadSpeed: "Unknown",
    uploadSpeed: "Unknown",
    ping: "Unknown",
    isp: "Unknown",
    networkScanTime: "Unknown",
  };
}
