import RESTGatewayAPI from "api/gatewayAPI";
import { getSignalRHub } from "app/SignalRHub/signalRHub";
import axios from "axios";
import { IServiceMessageGeneric } from "model/messaging/messages/IServiceMessageGeneric";
import { IAgentInfo, IRegState } from "model/scan/AgentInfo";
import { IServiceMessage, ServiceMessage, WSMessageType } from "ui.common";

declare let useSecureMessageHub: boolean;

export async function GetIsAgentOnline(uuid: string): Promise<boolean> {
  try {
    const url = `/api/onlinestatus/${uuid}`;

    const response = await RESTGatewayAPI.get(url);
    const apiResponse: boolean = response.data;

    return apiResponse;
  } catch (error) {
    throw new Error(`Unable to fetch agent online status : ${error}`);
  }
}

export async function EnsureSignalRConnection(uuid: string) {
  const hub = getSignalRHub();
  const srhub = hub.getInstance();
  //if we're connected to a different uuid for some reason, disconnect
  if (srhub.IsConnected && srhub.Uuid !== uuid) {
    await srhub.Disconnect();
  }
  //if we're not connected connect
  if (!srhub.IsConnected) {
    await srhub.Connect(uuid, useSecureMessageHub);
    SendWebUiLogon();
  }
}

async function SendWebUiLogon() {
  const hub = getSignalRHub();
  const srhub = hub.getInstance();
  /* eslint-disable-next-line no-restricted-globals */
  const qsParams = new URLSearchParams(location.search);
  const message: IServiceMessage = new ServiceMessage();
  message.MessageType = WSMessageType.WEB_UI_LOGON;
  message.Payload = {
    queryStringValues: Object.fromEntries(qsParams.entries()),
  };
  //fire and forget
  srhub.SendAsync(message);
}

//checks to see that an agent is on the machine running the webpage via local http server port
export async function GetIsAgentLocal(uuid: string, localPort: string): Promise<boolean> {
  try {
    const url = `http://localhost:${localPort}/${uuid}`;
    //create an axios connection to the local http server
    const conn = axios.create({
      baseURL: url,
    });
    //agent will return 200 only if the uuid matches
    const response = await conn.get(url);
    return response.status === 200;
  } catch (error) {
    throw new Error(`Error accessing local agent: ${error}`);
  }
}

//searches through 5 ports starting at 49217 attempting to get regstate from the
//agents http server.  If we get a successful response, return the json
export async function GetAgentRegstate(): Promise<IRegState> {
  const startingPort = 49217;
  const maxPortIncrements = 15;
  const baseUrl = `http://localhost`;

  let currentPort = startingPort;

  //build list of urls
  const urls: string[] = [];
  while (currentPort <= startingPort + maxPortIncrements) {
    urls.push(`${baseUrl}:${currentPort}/regstate`);
    currentPort++;
  }

  //array of fetch promises
  const requests = urls.map((url) => fetch(url));
  const results = await Promise.allSettled(requests);

  //check if we have a successful result and return the json
  const successfulResult = results.find(
    (result) => result.status === "fulfilled" && result.value.ok
  ) as PromiseFulfilledResult<Response>;
  if (successfulResult) {
    return successfulResult.value.json();
  } else {
    throw new Error("Error getting info from local agent");
  }
}

export async function GetAgentInfo(uuid: string): Promise<IServiceMessageGeneric<IAgentInfo>> {
  try {
    await EnsureSignalRConnection(uuid as string);

    const hub = getSignalRHub();
    const srhub = hub.getInstance();

    const message: IServiceMessage = new ServiceMessage();
    message.MessageType = WSMessageType.GET_AGENT_INFO;

    const response = (await srhub.SendAsync(message)) as IServiceMessageGeneric<IAgentInfo>;

    return response;
  } catch (error) {
    throw new Error(`Unable to get agent info : ${error}`);
  }
}
